import React from 'react';
import { Locations, Contacts, SocialNav } from '@components';
import gbaLogo from '@images/gba-logo.jpg';

const Footer = ({ siteTitle, fileLinks }) => (
  <footer className="site-footer" role="contentinfo" id="site-footer">
    <div className="page-wrapper">
      <div className="grid">
        <div className="grid__item">
          <Locations />
        </div>
        <div className="grid__item">
          <Contacts />
          <h2>
            <a href="https://www.gbateam.com/careers/">Careers</a>
          </h2>
        </div>
        <div className="grid__item">
          <h2>Follow Us</h2>
          <SocialNav ariaLabel="Footer Social Navigation" />
        </div>
        <div className="grid__item">
          <div className="footer__partners-wrapper">
            <h2>Partners</h2>
            <a href="https://www.gbateam.com/">
              <img src={gbaLogo} alt="GBA" />
            </a>
          </div>
        </div>
      </div>
      <p className="copyright">
        Copyright {new Date().getFullYear()} {siteTitle}.{' '}
        {fileLinks.map((item, index) => (
          <span key={index}>
            <a href={item.url}>{item.title}</a>
            {index + 1 < fileLinks.length ? ' | ' : null}
          </span>
        ))}
      </p>
      {/* <p className="copyright">Copyright {new Date().getFullYear()} {siteTitle}. <a href="/">Privacy Policy</a></p> */}
    </div>
  </footer>
);

export default Footer;
