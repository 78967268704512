import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Button = ({ classNames, text, type, isLink, url, handleClick, isNewWindow }) => {
  const buttonClassNames = classnames('button', classNames);
  if (isLink) {
    return (
      <a
        className={buttonClassNames}
        href={url}
        onClick={handleClick}
        rel={isNewWindow ? 'noopener noreferrer' : null}
        target={isNewWindow ? '_blank' : null}
      >{text}</a>
    )
  }
  return (
    <button className={buttonClassNames} type={type} onClick={handleClick}>{text}</button>
  );
};

Button.propTypes = {
  classNames: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  text: PropTypes.string.isRequired,
  isLink: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isNewWindow: PropTypes.bool.isRequired
};

Button.defaultProps = {
  text: 'Button',
  type: 'button',
  isLink: false,
  url: '#',
  handleClick: () => null,
  isNewWindow: false
};

export default Button;
