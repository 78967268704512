import React from 'react';
import { Button } from '@components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const InteriorHero = ({
  subtitle,
  title,
  description,
  button,
  className,
  bgColor
}) => {
  const renderSimpleDescription = desc => {
    return <p>{desc}</p>;
  };
  const renderComplexDescription = desc =>
    documentToReactComponents(description);

  return (
    <section
      className={`interior-hero ${
        bgColor ? `interior-hero--${bgColor}` : className
      }`}
    >
      <div className="page-wrapper">
        {subtitle && (
          <span className="interior-hero__subtitle">{subtitle}</span>
        )}
        <h1 className="interior-hero__title">{title}</h1>
        <div className="interior-hero__description">
          {typeof description === 'string'
            ? renderSimpleDescription(description)
            : renderComplexDescription(description)}
        </div>
        {button && <Button {...button} />}
      </div>
    </section>
  );
};

InteriorHero.defaultProps = {
  className: 'interior-hero--blue',
  bgColor: 'blue'
};

export default InteriorHero;
