import React from 'react';
import PropTypes from 'prop-types';

const ContentBlock = ({ image, content }) => (
  <div className="content-block">
    {image && (
      <img className="content-block__featured-image" src={image.src} alt={image.alt} />
    )}
    <div className="content-block__content" dangerouslySetInnerHTML={{ __html: content }} />
  </div>
);

ContentBlock.propTypes = {
  image: PropTypes.object,
  content: PropTypes.string.isRequired
};

export default ContentBlock;
