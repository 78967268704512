import React from 'react';
import { usePrimaryNavData } from '@hooks';
import { NavItem, Icon } from '@components';

const PrimaryNav = ({ navIsOpen, setNavIsOpen, ariaLabel }) => {

  const { items } = usePrimaryNavData();

  return (
    <nav className="primary-nav" role="navigation" aria-label={ariaLabel}>
      <ul className="primary-nav__list">
        {items.map((item, index) => (
          <NavItem
            key={index}
            itemClassNames={`primary-nav__item ${item.childPages ? 'has-children' : ''}`}
            linkClassNames="primary-nav__link"
            {...item}
          />
        ))}
      </ul>
      <button className="primary-nav__close-trigger" type="button" onClick={() => setNavIsOpen(!navIsOpen)}>
        <span className="is-vishidden">Close</span>
        <Icon classNames="primary-nav__close-trigger-icon" id="close" />
      </button>
    </nav>
  );
};

PrimaryNav.defaultProps = {
  ariaLabel: 'Primary Navigation'
};

export default PrimaryNav;