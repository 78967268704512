import React, { useState } from 'react';
import classnames from 'classnames';
import { useSiteSettingsData } from '@hooks';
import { SVGSprite, Header, Footer, Banner } from '@components';
import '@css/app.css';

const Layout = ({ contacts, children }) => {
  const { siteTitle, alertBanner, fileLinks } = useSiteSettingsData();
  const [navIsOpen, setNavIsOpen] = useState(false);

  const banner = alertBanner
    ? {
        message: alertBanner.title,
        url: alertBanner.url.slug
      }
    : null;

  return (
    <div
      className={classnames('site-wrapper', {
        'nav-is-open': navIsOpen
      })}
    >
      <SVGSprite />
      {banner && banner.message && <Banner {...banner} />}
      <Header
        siteTitle={siteTitle}
        navIsOpen={navIsOpen}
        setNavIsOpen={setNavIsOpen}
      />
      <main className="site-main" role="main" id="site-main">
        {children}
      </main>
      <Footer siteTitle={siteTitle} contacts={contacts} fileLinks={fileLinks} />
    </div>
  );
};

export default Layout;
