import { useStaticQuery, graphql } from 'gatsby';

const useContactsData = () => {
  const { contentfulSiteSettings: { footerContacts } } = useStaticQuery(graphql`
    query {
      contentfulSiteSettings {
        footerContacts {
          name
          phone
        }
      }
    }
  `);

  return footerContacts;
};

export default useContactsData;