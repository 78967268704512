import React from 'react';
import PropTypes from 'prop-types';
import { ContentPod } from '@components';

const ContentPodGroup = ({ contentPods }) => (
  <div className="content-pod-group">
    <div className="page-wrapper page-wrapper--wide">
      <div className="page-wrapper content-pod-group__items">
        {contentPods.map((contentPod, index) => (
          <ContentPod key={index} {...contentPod} />
        ))}
      </div>
    </div>
  </div>
);

ContentPodGroup.propTypes = {
  contentPods: PropTypes.array.isRequired
};

ContentPodGroup.defaultProps = {
  contentPods: []
};

export default ContentPodGroup;
