import React from 'react';
import classnames from 'classnames';
import Icon from '../Icon';

const ImageCard = ({ image, title, description, linkedIn, iconId, icon }) => (
  <div
    className={classnames(`image-card`, {
      [`image-card--${iconId}`]: iconId,
      'image-card--has-icon': iconId
    })}
  >
    <div className="image-card__image-wrapper">
      <img className="image-card__image" src={image} alt="{title}" />
      {icon?.file?.url && (
        <img className="image-card__icon" src={icon.file.url} alt={title} />
      )}
    </div>
    <div className="image-card__content-wrapper">
      <h2 className="image-card__title">{title}</h2>
      <p className="image-card__description">{description}</p>
      {linkedIn && (
        <a className="image-card__linkedin" href={linkedIn}>
          <Icon id="linkedin" />
          <span className="is-vishidden">LinkedIn</span>
        </a>
      )}
    </div>
  </div>
);

export default ImageCard;
