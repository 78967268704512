import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import logo from '@images/gbasi-logo.svg';

const SiteLogo = ({ siteTitle }) => (
  <Link className="site-logo__link" to="/">
    <img className="site-logo__image" src={logo} alt={siteTitle} />
  </Link>
);

SiteLogo.propTypes = {
  siteTitle: PropTypes.string.isRequired
};

export default SiteLogo;