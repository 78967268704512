import { useStaticQuery, graphql } from "gatsby"

const usePrimaryNavData = () => {
  const {
    contentfulSiteSettings: { primaryNavigationItems },
  } = useStaticQuery(graphql`
    query {
      contentfulSiteSettings {
        primaryNavigationItems {
          title
          slug
          childPages {
            ... on ContentfulContentPages {
              id
              slug
              title
            }
            ... on ContentfulSpecialtyServicesPages {
              id
              slug
              title
            }
            ... on ContentfulTeamPages {
              slug
              title
            }
            ... on ContentfulWhitePapersPages {
              slug
              title
            }
          }
        }
      }
    }
  `)
  return {
    items: primaryNavigationItems.map(
      ({ title, slug: parentSlug, childPages }) => ({
        id: parentSlug,
        url: `/${parentSlug}`,
        text: title,
        items: childPages
          ? childPages.map(({ title, slug: childSlug }) => ({
              id: childSlug,
              url: `/${childSlug}`,
              text: title,
            }))
          : null,
      })
    ),
  }
}

export default usePrimaryNavData
