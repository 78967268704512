import React from 'react';
import { ImageCard } from '@components';

const ImageCardGrid = ({ items, description, className }) => (
  <section className={`image-card-grid ${className}`}>
    <div className="image-card-grid__items">
      {items.map((item, index) =>
        <ImageCard key={index} {...item} />
      )}
    </div>
    {description && (
      <p className="image-card-grid__description">{description}</p>
    )}
  </section>
);

ImageCardGrid.defaultProps = {
  className: 'image-card-grid--three-wide',
  items: []
};

export default ImageCardGrid;