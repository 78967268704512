import React from 'react';
import { Icon } from '@components';

const HamburgerMenu = ({ navIsOpen, setNavIsOpen }) => (
  <button
    className="hamburger-menu"
    aria-label="Mobile Navigation"
    type="button"
    onClick={() => setNavIsOpen(!navIsOpen)}
  >
    <Icon classNames="hamburger-menu__icon" id="hamburger" />
  </button>
);

export default HamburgerMenu;
