import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image'
import { Button } from '@components';

const Hero = ({ image, title, description, button }) => {
  let normalizedImageProps = image;
  if (image.fluid && image.fluid.presentationWidth) {
    normalizedImageProps = {
      ...image,
      style: {
        ...(image.style || {}),
        maxWidth: image.fluid.presentationWidth,
        margin: '0 auto'
      },
    }
  }

  return (
    <BackgroundImage
      Tag="section"
      className="hero"
      {...normalizedImageProps}
      backgroundColor={`#040e18`}
    >
      <div className="page-wrapper">
        <div className="hero__content-wrapper">
          <h1 className="hero__title">{title}</h1>
          {title && <p className="hero__description">{description}</p>}
          {button && <Button {...button} />}
        </div>
      </div>
    </BackgroundImage>
  );
};

Hero.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  button: PropTypes.object
};

Hero.defaultProps = {
  title: `Hero Title`
};

export default Hero;
