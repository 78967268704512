import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const DocumentList = ({ title, categories, items }) => {
  const [currentCategory, setCurrentCategory] = useState('all');

  return (
    <section className="document-list">
      <div className="document-list__controls">
        <h1 className="document-list__title">{title}</h1>
        {/* <form className="document-list__filter">
          <div className="document-list__filter-field">
            <label
              className="document-list__filter-field-label"
              htmlFor="filters"
            >
              Filter
            </label>
            <select
              className="document-list__filter-field-select select"
              id="filters"
              onChange={e => setCurrentCategory(e.target.value)}
            >
              <option value="all">Include All Categories</option>
              {categories.map(({ id, pluralLabel }) => (
                <option key={id} value={id}>
                  {pluralLabel}
                </option>
              ))}
            </select>
          </div>
        </form> */}
      </div>
      <div className="document-list__items">
        {items
          .filter(
            ({ category }) =>
              currentCategory === 'all' || category === currentCategory
          )
          .map(({ title, url }, index) => {
            return (
              <div key={index} className="document-list__item">
                <div className="document-list__icon-wrapper">
                  <Document
                    file={url}
                    className="document-list__icon document-list__icon--whitepapers"
                  >
                    <Page pageNumber={1} height={220} width={164} />
                  </Document>
                </div>
                <h2 className="document-list__item-title">{title}</h2>
                <a className="button" href={url}>
                  Download
                </a>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default DocumentList;
