import React from 'react';
import { useContactsData } from '@hooks';
import { Contact } from '@components';

const Contacts = () => {

  const contacts = useContactsData();

  return (
    <div className="contacts">
      <h2>Contact Us</h2>
      <div className="contacts__items">
        {contacts && contacts.map((contact, index) => (
          <Contact key={index} {...contact} />
        ))}
      </div>
    </div>
  );
};

export default Contacts;