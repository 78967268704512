import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@components';
import Img from 'gatsby-image';

const ContentPod = ({ title, image, imageStyle, description, button }) => (
  <section className="content-pod">
    <div className={`content-pod__image-wrapper ${imageStyle}`}>
      <Img className="content-pod__image" fixed={image.fixed} />
    </div>
    <div className="content-pod__content-wrapper">
      <h1 className="content-pod__title">{title}</h1>
      <p className="content-pod__description">{description}</p>
      {button && <Button classNames="button--tertiary" isLink={true} {...button} />}
    </div>
  </section>
);

ContentPod.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.object
};

ContentPod.defaultProps = {
  title: 'Content Pod Title',
  description: 'Content Pod Description'
};

export default ContentPod;
