import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@components';

const Link = ({ url, message }) => (
  <a className="banner__link" href={url}>
    <MessageWrapper isLink={true} message={message} />

  </a>
);

const MessageWrapper = ({ isLink, message }) => (
  <div className="page-wrapper">
    <h1 className="banner__title"><span dangerouslySetInnerHTML={{ __html: message }} />{isLink && <Icon classNames="banner__icon" id="caret" />}</h1>

  </div>
);

const Banner = ({ url, message }) => (
  <section className="banner">
    {url ? <Link url={url} message={message} /> : <MessageWrapper message={message} />}

  </section>
);

Banner.propTypes = {
  url: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default Banner;
