import { useStaticQuery, graphql } from 'gatsby';

const useSocialNavData = () => {
  const { contentfulSiteSettings: { socialNavigation } } = useStaticQuery(graphql`
    query {
      contentfulSiteSettings {
        socialNavigation {
          title
          url
          slug
        }
      }
    }
  `);

  return {
    items: socialNavigation.map(({ title, url, slug }) => ({
      id: slug,
      url,
      text: title
    }))
  };
};

export default useSocialNavData;