import React from 'react';
import PropTypes from 'prop-types';
import { NavItem } from '@components';

const SecondaryNav = ({ items, ariaLabel }) => (
  <ul className="secondary-nav__list">
    {items.map((item, index) => (
      <NavItem key={index} itemClassNames="secondary-nav__item" linkClassNames="secondary-nav__link" {...item} />
    ))}
  </ul>
);

SecondaryNav.propTypes = {
  items: PropTypes.array.isRequired,
  ariaLabel: PropTypes.string.isRequired
};

SecondaryNav.defaultProps = {
  items: [],
  ariaLabel: 'Secondary Navigation'
};

export default SecondaryNav;