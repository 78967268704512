import { useStaticQuery, graphql } from 'gatsby';

const useLocationsData = () => {
  const { contentfulSiteSettings: { footerLocations } } = useStaticQuery(graphql`
    query {
      contentfulSiteSettings {
        footerLocations {
          address1
          address2
          city
          state
          zip
          phone
        }
      }
    }
  `);

  return footerLocations;
};

export default useLocationsData;