import React from 'react';
import { Button } from '@components';
import PropTypes from 'prop-types';

const IconPanel = ({ title, description, items, button }) => (
  <section className="icon-panel">
    <div className="page-wrapper">
      <h1 className="icon-panel__title">{title}</h1>
      <div className="icon-panel__items">
        {items.map(({ id, url, text, icon }) => {
          if (url) {
            return (
              <a key={id} className="icon-panel__item" href={url}>
                <div
                  className={`icon-panel__icon-wrapper icon-panel__icon-wrapper--${id}`}
                >
                  {icon && (
                    <img
                      className="icon-panel__icon"
                      src={icon.file.url}
                      alt={title}
                    />
                  )}
                </div>
                <div
                  className="icon-peanl__item-text"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </a>
            );
          }
          return (
            <div key={id} className="icon-panel__item">
              <div
                className={`icon-panel__icon-wrapper icon-panel__icon-wrapper--${id}`}
              >
                {icon && (
                  <img
                    className="icon-panel__icon"
                    src={icon.file.url}
                    alt={title}
                  />
                )}
              </div>
              <div
                className="icon-peanl__item-text"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          );
        })}
      </div>
      {description && <p className="icon-panel__description">{description}</p>}
      {button && (
        <Button classNames="button--secondary" {...button} isLink={true} />
      )}
    </div>
  </section>
);

IconPanel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  items: PropTypes.array.isRequired,
  button: PropTypes.object
};

IconPanel.defaultProps = {
  title: 'Icon Panel',
  items: []
};

export default IconPanel;
