import { useStaticQuery, graphql } from 'gatsby';

const useSiteSettingsData = () => {
  const { contentfulSiteSettings } = useStaticQuery(graphql`
    query {
      contentfulSiteSettings {
        siteName
        favicon {
          file {
            url
          }
        }
        alertBanner {
          title
          url {
            slug
          }
        }
        footerFileLinks {
          file {
            url
          }
          title
        }
      }
    }
  `);
  return {
    siteTitle: contentfulSiteSettings.siteName,
    alertBanner: contentfulSiteSettings.alertBanner,
    fileLinks: contentfulSiteSettings.footerFileLinks.map(item => ({
      title: item.title,
      url: item.file.url
    }))
  };
};

export default useSiteSettingsData;
