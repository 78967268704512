import React from 'react';
import { useSocialNavData } from '@hooks';
import { Icon } from '@components';

const SocialNav = ({ title, ariaLabel }) => {

  const { items } = useSocialNavData();

  return (
    <nav className="social-nav" role="navigation" aria-label={ariaLabel}>
      {title && <h1 className="social-nav__title">{title}</h1>}
      <ul className="social-nav__list">
        {items.map(({ id, url, text }) => (
          <li key={id} className="social-nav__item">
            <a className="social-nav__link" href={url}>
              <span className="is-vishidden">{text}</span>
              <Icon id={id} classNames="social-nav__icon" />
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

SocialNav.defaultProps = {
  ariaLabel: 'Social Navigation'
};

export default SocialNav;