import { useStaticQuery, graphql } from 'gatsby';

const useHomepageData = () => {
  const { contentfulHomepage } = useStaticQuery(graphql`
    query {
      contentfulHomepage {
        pageDescription
        hero {
          image {
            fluid(maxWidth: 1920) {
              aspectRatio
              sizes
              src
              srcSetWebp
              srcSet
              srcWebp
            }
          }
          url {
            slug
          }
          title
          buttonText
          description {
            description
          }
        }
        services {
          title
          description {
            description
          }
          buttonText
          buttonUrl {
            slug
          }
          items {
            iconId
            title
            icon {
              file {
                url
              }
            }
          }
        }
        contentPodGroup {
          title
          description {
            description
          }
          buttonText
          url {
            ... on ContentfulWhitePapersPages {
              slug
            }
            ... on ContentfulTeamPages {
              slug
            }
          }
          imageStyle
          image {
            fixed(width: 270) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  `);

  return {
    ...contentfulHomepage,
    homepage: {
      pageTitle: contentfulHomepage.pageDescription
    },
    hero: {
      ...contentfulHomepage.hero,
      description: contentfulHomepage.hero.description.description,
      button: {
        isLink: true,
        text: contentfulHomepage.hero.buttonText,
        url: contentfulHomepage.hero.url.slug
      }
    },
    iconPanel: {
      title: contentfulHomepage.services[0].title,
      description: contentfulHomepage.services[0].description.description,
      items: contentfulHomepage.services[0].items.map(
        ({ iconId, title, url, icon }) => ({
          id: iconId,
          url,
          text: title,
          icon
        })
      ),
      button: {
        text: contentfulHomepage.services[0].buttonText,
        url: contentfulHomepage.services[0].buttonUrl.slug
      }
    },
    contentPodGroup: {
      contentPods: contentfulHomepage.contentPodGroup.map(
        ({ title, imageStyle, image, description, buttonText, url }) => ({
          title,
          description: description.description,
          imageStyle: imageStyle.replace(/\s+/g, '-').toLowerCase(),
          image,
          button: {
            text: buttonText,
            url: url?.slug
          }
        })
      )
    }
  };
};

export default useHomepageData;
