import React from 'react';
import { useLocationsData } from '@hooks';
import { Location } from '@components';

const Locations = () => {

  const locations = useLocationsData();

  return (
    <div className="locations">
      <h2>Locations</h2>
      <div className="locations__items">
        {locations && locations.map((location, index) => (
          <Location key={index} {...location} />
        ))}
      </div>
    </div >
  );
};

export default Locations;
