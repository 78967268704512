import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { Icon, SecondaryNav } from '@components';

const NavItem = ({ itemClassNames, linkClassNames, id, url, text, items }) => {

  const isInternal = /^\/(?!\/)/.test(url);

  const Wrapper = ({ children, items }) => (
    <li key={id} className={classnames(itemClassNames, {
      'has-children': items
    })}>
      {children}
    </li>
  );

  if (isInternal) {
    const strippedText = text.replace('&amp;', '&');
    return (
      <Wrapper items={items}>
        <Link
          to={url}
          className={linkClassNames}>
          <span>{strippedText}</span>
          {items && <Icon id="caret" />}
        </Link>
        {items && <SecondaryNav items={items} />}
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <a href={url} dangerouslySetInnerHTML={{ __html: text }} />
    </Wrapper>
  );
};

NavItem.propTypes = {
  itemClassNames: PropTypes.string.isRequired,
  linkClassNames: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  itemClassNames: `nav__item`,
  linkClassNames: `nav__link`,
  url: '#',
  text: 'Nav Item'
};

export default NavItem;