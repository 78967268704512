import React, { useState } from 'react';
import Calendar from 'tt-react-calendar';
import moment from 'moment';
import { Icon } from '@components';

const format = 'MM/DD/YYYY';

const EventsCalendar = ({ events }) => {
  const [currentDate, setCurrentDate] = useState(moment().format(format));
  const [firstDay, setFirstDay] = useState(
    moment(currentDate)
      .startOf('month')
      .format(format)
  );
  const [lastDay, setLastDay] = useState(
    moment(currentDate)
      .endOf('month')
      .format(format)
  );

  const renderDay = day => {
    const dayDate = day.format('MM/DD/YY');
    const matchingEvents = events.filter(({ date }) => date === dayDate);

    return (
      <div className="calendar__day">
        <div className="calendar__day-number">{day.format('D')}</div>
        {matchingEvents && matchingEvents.length > 0 && (
          <ul className="calendar__events">
            {matchingEvents.map(({ title, url }, index) => (
              <li key={index} className="calendar__event" title={title}>
                <a className="calendar__event-link" href={url}>
                  {title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const handleClick = method => {
    const newDate = moment(currentDate)
      [method](1, 'month')
      .format(format);
    setCurrentDate(newDate);
    setFirstDay(
      moment(newDate)
        .startOf('month')
        .format(format)
    );
    setLastDay(
      moment(newDate)
        .endOf('month')
        .format(format)
    );
  };

  return (
    <div className="events__wrapper">
      <div className="events__arrows">
        <button
          className="button button--bare events__arrow events__arrow--previous"
          title="Previous Month"
          onClick={() => handleClick('subtract')}
        >
          <Icon id="left-arrow" />
          <span className="is-vishidden">Previous</span>
        </button>
        <button
          className="button button--bare events__arrow events__arrow--next"
          title="Next Month"
          onClick={() => handleClick('add')}
        >
          <Icon id="right-arrow" />
          <span className="is-vishidden">Next</span>
        </button>
      </div>
      <Calendar
        dayAbbrevs={['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']}
        className="calendar__container"
        monthClassName="calendar__month"
        dayHeaderClassName="calendar__day-header"
        firstRenderedDay={firstDay}
        lastRenderedDay={lastDay}
        renderDay={renderDay}
      />
      <div className="events__list-wrapper">
        <ul className="events__list">
          {events.map((event, index) => (
            <li className="events__list-item" key={index}>
              <a className="events__link" href={event.url}>
                {event.title} - {event.date}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EventsCalendar;
