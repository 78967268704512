import React from 'react';
import { SiteLogo, PrimaryNav, SocialNav, HamburgerMenu } from '@components';

const Header = ({ navIsOpen, setNavIsOpen, siteTitle }) => (
  <header className="site-header" role="banner" id="site-header">
    <div className="page-wrapper page-wrapper--wide">
      <HamburgerMenu navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
      <SiteLogo siteTitle={siteTitle} />
      <PrimaryNav navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
      <SocialNav title="Follow Us:" ariaLabel="Header Social Navigation" />
    </div>
  </header>
);

export default Header;
