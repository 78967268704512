import React from 'react';
import PropTypes from 'prop-types';

const Contact = ({ name, phone }) => (
  <div className="contact" itemScope itemType="http://schema.org/Person">
    <div className="contact__row" itemProp="name">{name}</div>
    <a className="contact__row" itemProp="telephone" href={`tel:${phone}`}>{phone}</a>
  </div>
);

Contact.propTypes = {
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired
};

export default Contact;
