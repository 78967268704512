import React from 'react';
import PropTypes from 'prop-types';

const Location = ({ address1, address2, city, state, zip, phone }) => (
    <div className="location" itemScope itemType="http://schema.org/PostalAddress">
        <span itemProp="streetAddress">
            {address1 && <div className="location__row">{address1}</div>}
            {address2 && <div className="location__row">{address2}</div>}
        </span>
        <div className="location__row"><span itemProp="addressLocality">{city}</span>, <span itemProp="addressRegion">{state}</span> <span itemProp="postalCode">{zip}</span></div>
        {phone && <a className="location__row" itemProp="telephone" href={`tel:${phone}`}>{phone}</a>}
    </div >
);

Location.propTypes = {
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    phone: PropTypes.string
};

export default Location;
