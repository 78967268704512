import React from 'react';

const SVGSprite = () => (
  <svg
    aria-hidden="true"
    style={{
      height: 0,
      overflow: 'hidden',
      position: 'absolute',
      width: 0
    }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <symbol id="icon-hamburger" viewBox="0 0 32 32">
      <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
    </symbol>
    <symbol id="icon-close" viewBox="0 0 512 512">
      <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
    </symbol>
    <symbol id="icon-facebook" viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" />
      <path
        id="f"
        fill="#fff"
        d="M16.4,23.9v-8.1h2.7l0.4-3.2h-3.1v-2c0-0.9,0.3-1.5,1.6-1.5l1.7,0V6.2c-0.3,0-1.3-0.1-2.4-0.1c-2.4,0-4.1,1.5-4.1,4.2v2.3h-2.7v3.2h2.7v8.1H16.4z"
      />
    </symbol>
    <symbol
      id="icon-linkedin"
      viewBox="0 0 3333 3333"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm-215 1336h342v175h5c48-86 164-175 338-175 361 0 428 225 428 517v596h-357v-528c0-126-3-288-186-288-186 0-214 137-214 279v537h-357V1336zm-247-309c0 102-83 186-186 186-102 0-186-83-186-186 0-102 83-186 186-186 102 0 186 83 186 186zm-371 309h371v1113H834V1336z" />
    </symbol>
    <symbol
      id="icon-caret"
      viewBox="0 0 24.04 10.8"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M11.35,10.8a1,1,0,0,1-.64-.24L.35,1.76A1,1,0,1,1,1.65.24l9.74,8.27L22.43.2a1,1,0,0,1,1.21,1.6L12,10.59A1,1,0,0,1,11.35,10.8Z" />
    </symbol>
    <symbol id="icon-left-arrow" viewBox="0 0 492 492">
      <path
        d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
			C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
			c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
			l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"
      />
    </symbol>
    <symbol id="icon-right-arrow" viewBox="0 0 492.004 492.004">
      <path
        d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12
			c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028
			c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265
			c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z"
      />
    </symbol>

    <symbol
      id="icon-scatterplot"
      viewBox="0 0 82.51 78.75"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M6.4,37.89a6.41,6.41,0,1,1,6.41-6.41A6.41,6.41,0,0,1,6.4,37.89Zm0-10.81a4.41,4.41,0,1,0,4.41,4.4A4.41,4.41,0,0,0,6.4,27.08Z" />
      <path d="M14.4,78.75a6.4,6.4,0,1,1,6.41-6.4A6.41,6.41,0,0,1,14.4,78.75Zm0-10.8a4.4,4.4,0,1,0,4.41,4.4A4.41,4.41,0,0,0,14.4,68Z" />
      <path d="M67.91,70.18a6.4,6.4,0,1,1,6.41-6.4A6.41,6.41,0,0,1,67.91,70.18Zm0-10.8a4.4,4.4,0,1,0,4.41,4.4A4.41,4.41,0,0,0,67.91,59.38Z" />
      <path d="M76.1,23.61a6.4,6.4,0,1,1,6.41-6.4A6.41,6.41,0,0,1,76.1,23.61Zm0-10.8a4.4,4.4,0,1,0,4.41,4.4A4.4,4.4,0,0,0,76.1,12.81Z" />
      <path d="M40.26,53.32a6.4,6.4,0,1,1,6.41-6.4A6.41,6.41,0,0,1,40.26,53.32Zm0-10.8a4.4,4.4,0,1,0,4.41,4.4A4.4,4.4,0,0,0,40.26,42.52Z" />
      <path d="M57.11,36.48a6.41,6.41,0,1,1,6.4-6.4A6.41,6.41,0,0,1,57.11,36.48Zm0-10.81a4.41,4.41,0,1,0,4.4,4.41A4.42,4.42,0,0,0,57.11,25.67Z" />
      <path d="M29.46,12.81a6.41,6.41,0,1,1,6.4-6.41A6.42,6.42,0,0,1,29.46,12.81ZM29.46,2a4.41,4.41,0,1,0,4.4,4.4A4.42,4.42,0,0,0,29.46,2Z" />
      <path d="M12.81,68.06a1,1,0,0,1-1-.79L5.43,37.1a1,1,0,1,1,2-.42l6.41,30.18A1,1,0,0,1,13,68,.68.68,0,0,1,12.81,68.06Z" />
      <path d="M19.81,73.35a1,1,0,0,1-.2-2l42.7-8.57a1,1,0,1,1,.4,2L20,73.33Z" />
      <path d="M9.4,28.08a1,1,0,0,1-.69-.28,1,1,0,0,1,0-1.41L24.33,10.11a1,1,0,0,1,1.42,0,1,1,0,0,1,0,1.41L10.12,27.77A1,1,0,0,1,9.4,28.08Z" />
      <path d="M34.86,44a1.06,1.06,0,0,1-.45-.11l-23-11.52A1,1,0,0,1,10.91,31a1,1,0,0,1,1.34-.45L35.31,42.11a1,1,0,0,1,.44,1.34A1,1,0,0,1,34.86,44Z" />
      <path d="M54.11,25.67a1,1,0,0,1-.69-.27L34.17,7.13a1,1,0,1,1,1.38-1.45L54.79,24a1,1,0,0,1,0,1.41A1,1,0,0,1,54.11,25.67Z" />
      <path d="M67.92,59.38a.91.91,0,0,1-.23,0,1,1,0,0,1-.75-1.2l8.19-35.76a1,1,0,0,1,1.2-.75,1,1,0,0,1,.75,1.2L68.89,58.6A1,1,0,0,1,67.92,59.38Z" />
      <path d="M61.51,26.67a1,1,0,0,1-.74-.32,1,1,0,0,1,.06-1.41L70,16.48a1,1,0,0,1,1.41.05A1,1,0,0,1,71.38,18l-9.19,8.46A1,1,0,0,1,61.51,26.67Z" />
      <path d="M44.67,43.92a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l8.44-8.44a1,1,0,0,1,1.41,0,1,1,0,0,1,0,1.42l-8.44,8.44A1,1,0,0,1,44.67,43.92Z" />
      <path d="M17.81,67.78A1,1,0,0,1,17.16,66L35.21,50.56a1,1,0,0,1,1.3,1.52l-18,15.46A1,1,0,0,1,17.81,67.78Z" />
      <path d="M40.26,42.52a1,1,0,0,1-.94-.66L28.52,12.15a1,1,0,1,1,1.88-.68L41.2,41.18a1,1,0,0,1-.6,1.28A1,1,0,0,1,40.26,42.52Z" />
      <path d="M63.51,60.32a1.11,1.11,0,0,1-.43-.09l-18.84-9a1,1,0,1,1,.86-1.81l18.84,9a1,1,0,0,1-.43,1.9Z" />
      <path d="M16.23,67.38l-.2,0a1,1,0,0,1-.78-1.18L25.85,13a1,1,0,1,1,2,.39L17.21,66.57A1,1,0,0,1,16.23,67.38Z" />
      <path d="M64.51,58.82a1,1,0,0,1-1-.7l-6.4-21a1,1,0,0,1,1.91-.58l6.41,21a1,1,0,0,1-.67,1.25A1,1,0,0,1,64.51,58.82Z" />
    </symbol>
    <symbol
      id="icon-lightbulb"
      viewBox="0 0 55.41 81.99"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M37.83,74.86H17.58a1,1,0,0,1,0-2H37.83a1,1,0,0,1,0,2Z" />
      <path d="M38.2,69H16.58a1,1,0,1,1,0-2H38.2a1,1,0,0,1,0,2Z" />
      <path d="M38.83,62.08H16.58a1,1,0,0,1-1-1c0-4.66-2.8-7.78-6-11.4C5.07,44.7,0,39.05,0,27.7a27.71,27.71,0,0,1,55.41,0c0,11.35-5.07,17-9.54,22-3.24,3.62-6,6.74-6,11.4A1,1,0,0,1,38.83,62.08Zm-21.29-2H37.87c.36-4.87,3.35-8.21,6.51-11.73,4.23-4.72,9-10.06,9-20.65A25.71,25.71,0,0,0,2,27.7c0,10.59,4.79,15.93,9,20.65C14.18,51.87,17.18,55.21,17.54,60.08Z" />
      <path d="M27.7,82A12.14,12.14,0,0,1,15.58,69.86h0V60.94a1,1,0,0,1,1-1H38.83a1,1,0,0,1,1,1v8.92A12.14,12.14,0,0,1,27.7,82ZM17.58,69.86a10.13,10.13,0,1,0,20.25,0V61.94H17.58Z" />
      <rect x="26.54" y="38.94" width="2.33" height="23.14" rx="1" />
      <path d="M27.71,41.13a1.12,1.12,0,0,1-.47-.11l-6.17-3.17-2.92,2.92a1,1,0,0,1-1.42,0l-.23-.24a1,1,0,0,1-.29-.7,1,1,0,0,1,.29-.71l3.61-3.61a1,1,0,0,1,1.16-.18l6.44,3.3,6.43-3.3a1,1,0,0,1,1.17.19l.08.08,3.52,3.52a1,1,0,0,1,.29.71,1,1,0,0,1-.29.7l-.24.24a1,1,0,0,1-1.41,0l-2.92-2.92L28.16,41A1,1,0,0,1,27.71,41.13Z" />
    </symbol>
    <symbol
      id="icon-labyrinth"
      viewBox="0 0 86.25 91.32"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M43.12,62.85A17.44,17.44,0,1,1,60.56,45.42,17.45,17.45,0,0,1,43.12,62.85Zm0-32.87A15.44,15.44,0,1,0,58.56,45.42,15.45,15.45,0,0,0,43.12,30Z" />
      <path d="M72,38.24a1,1,0,0,1-1-.73,29.06,29.06,0,0,0-55.91,0A1,1,0,0,1,13.24,37,31.06,31.06,0,0,1,73,37a1,1,0,0,1-.69,1.23A.9.9,0,0,1,72,38.24Z" />
      <path d="M43.12,76.46A31.16,31.16,0,0,1,13.24,53.87a1,1,0,0,1,1.93-.54,29.06,29.06,0,0,0,55.91,0,1,1,0,1,1,1.92.55A31.15,31.15,0,0,1,43.12,76.46Z" />
      <path d="M51,87.81a1,1,0,0,1-.18-2A41.13,41.13,0,0,0,50.8,5a1,1,0,1,1,.37-2,43.13,43.13,0,0,1,0,84.74Z" />
      <path d="M35.36,87.83l-.18,0a43.13,43.13,0,0,1-.1-84.76,1,1,0,1,1,.37,2,41.13,41.13,0,0,0,.09,80.83,1,1,0,0,1-.18,2Z" />
      <path d="M43.17,91.32a4.06,4.06,0,1,1,4.06-4.06A4.06,4.06,0,0,1,43.17,91.32Zm0-6.12a2.06,2.06,0,1,0,2.06,2.06A2.06,2.06,0,0,0,43.17,85.2Z" />
      <path d="M43.17,8.12a4.06,4.06,0,1,1,4.06-4.06A4.06,4.06,0,0,1,43.17,8.12Zm0-6.12a2.06,2.06,0,1,0,2.06,2.06A2.06,2.06,0,0,0,43.17,2Z" />
      <path d="M75.8,45.2H68.28a1,1,0,0,1-1-1V36.67a1,1,0,0,1,1-1H75.8a1,1,0,0,1,1,1V44.2A1,1,0,0,1,75.8,45.2Zm-6.52-2H74.8V37.67H69.28Z" />
      <path d="M18,55.19H10.44a1,1,0,0,1-1-1V46.66a1,1,0,0,1,1-1H18a1,1,0,0,1,1,1v7.53A1,1,0,0,1,18,55.19Zm-6.53-2H17V47.66H11.44Z" />
      <path d="M44.5,46.42H26.69a1,1,0,1,1,0-2H44.13l11.32-9.81a1,1,0,0,1,1.31,1.51L45.16,46.17A1,1,0,0,1,44.5,46.42Z" />
      <path d="M55.26,57.1a1,1,0,0,1-.71-.29L43.8,46.13a1,1,0,0,1,0-1.42,1,1,0,0,1,1.41,0L56,55.39a1,1,0,0,1,0,1.42A1,1,0,0,1,55.26,57.1Z" />
    </symbol>
    <symbol
      id="icon-shield"
      viewBox="0 0 72.82 87.12"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M36.41,87.12a1,1,0,0,1-.57-.18L21,76.6a48.8,48.8,0,0,1-21-40V16.28a1,1,0,0,1,.81-1L7.64,14A54.21,54.21,0,0,0,28.71,5.28l7.4-5.1a1,1,0,0,1,1.15,0l6.41,4.59a54.1,54.1,0,0,0,20.87,9.08L72,15.38a1,1,0,0,1,.8,1v20.7A47.9,47.9,0,0,1,52.23,76.33L37,86.94A1,1,0,0,1,36.41,87.12ZM2,17.11V36.59A46.79,46.79,0,0,0,22.13,75L36.41,84.9,51.08,74.69A45.91,45.91,0,0,0,70.82,37.06V17.18l-6.68-1.36A56,56,0,0,1,42.51,6.41L36.66,2.22,29.84,6.93A56.17,56.17,0,0,1,8,16Z" />
      <path d="M36.41,75.81a1,1,0,0,1-.57-.18l-10-7A38.25,38.25,0,0,1,9.37,37.29V23.78a1,1,0,0,1,.82-1l1.43-.27a54.36,54.36,0,0,0,21.12-8.75L36,11.51a1,1,0,0,1,1.15,0l2.43,1.74a54.13,54.13,0,0,0,21,9.13l2.09.43a1,1,0,0,1,.8,1V37.72a37.44,37.44,0,0,1-16.1,30.69L37,75.63A1,1,0,0,1,36.41,75.81Zm-25-51.2V37.29A36.25,36.25,0,0,0,27,67l9.45,6.58,9.8-6.82a35.45,35.45,0,0,0,15.24-29V24.61l-1.29-.26a56.24,56.24,0,0,1-21.73-9.46l-1.85-1.33-2.7,1.86A56.2,56.2,0,0,1,12,24.49Z" />
      <path d="M45.06,54.27H27.76a1,1,0,0,1-1-1V39.19a1,1,0,0,1,1-1h17.3a1,1,0,0,1,1,1V53.27A1,1,0,0,1,45.06,54.27Zm-16.3-2h15.3V40.19H28.76Z" />
      <path d="M42.23,40.19H30.59a1,1,0,0,1-1-1V37.81a6.82,6.82,0,0,1,13.64,0v1.38A1,1,0,0,1,42.23,40.19Zm-10.64-2h9.64v-.38a4.82,4.82,0,0,0-9.64,0Z" />
    </symbol>
    <symbol
      id="icon-gears"
      viewBox="0 0 75.6 75.6"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M25.54,60.67a10.6,10.6,0,1,1,10.59-10.6A10.62,10.62,0,0,1,25.54,60.67Zm0-19.2a8.6,8.6,0,1,0,8.59,8.6A8.61,8.61,0,0,0,25.54,41.47Z" />
      <path d="M25.54,75.6a25.8,25.8,0,0,1-4.18-.34,1,1,0,0,1-.82-.85l-.72-5a19.37,19.37,0,0,1-3.94-1.63l-4,3a1,1,0,0,1-1.18,0,25.42,25.42,0,0,1-5.9-5.9,1,1,0,0,1,0-1.19l3-4a20,20,0,0,1-1.63-3.94l-5-.71a1,1,0,0,1-.85-.83,25.74,25.74,0,0,1,0-8.34,1,1,0,0,1,.85-.83l5-.71a19.47,19.47,0,0,1,1.63-3.94l-3-4a1,1,0,0,1,0-1.18,25.42,25.42,0,0,1,5.9-5.9,1,1,0,0,1,1.18,0l4,3a20,20,0,0,1,3.94-1.63l.72-5a1,1,0,0,1,.82-.84,25.43,25.43,0,0,1,8.35,0,1,1,0,0,1,.82.84l.72,5a19.62,19.62,0,0,1,3.93,1.63l4-3a1,1,0,0,1,1.18,0,25.42,25.42,0,0,1,5.9,5.9,1,1,0,0,1,0,1.18l-3,4a19.47,19.47,0,0,1,1.63,3.94l5,.71a1,1,0,0,1,.85.83,25.74,25.74,0,0,1,0,8.34,1,1,0,0,1-.85.83l-5,.71a20,20,0,0,1-1.63,3.94l3,4a1,1,0,0,1,0,1.19,25.42,25.42,0,0,1-5.9,5.9,1,1,0,0,1-1.18,0l-4-3a19.05,19.05,0,0,1-3.93,1.63l-.72,5a1,1,0,0,1-.82.85A25.77,25.77,0,0,1,25.54,75.6Zm-3.13-2.21a23.36,23.36,0,0,0,6.25,0l.7-4.9a1,1,0,0,1,.74-.82,18.07,18.07,0,0,0,4.65-1.93,1,1,0,0,1,1.11.06l4,3a24,24,0,0,0,4.41-4.42l-3-4a1,1,0,0,1-.06-1.1,18.11,18.11,0,0,0,1.92-4.66,1,1,0,0,1,.83-.74l4.9-.7a23.36,23.36,0,0,0,0-6.25l-4.9-.7a1,1,0,0,1-.83-.74,18,18,0,0,0-1.92-4.65,1,1,0,0,1,.06-1.11l3-4a23.37,23.37,0,0,0-4.41-4.42l-4,3a1,1,0,0,1-1.11.06,17.89,17.89,0,0,0-4.65-1.92,1,1,0,0,1-.74-.83l-.7-4.9a23.36,23.36,0,0,0-6.25,0l-.7,4.9a1,1,0,0,1-.74.83,17.89,17.89,0,0,0-4.65,1.92,1,1,0,0,1-1.11-.06l-4-3a23.72,23.72,0,0,0-4.42,4.42l3,4a1,1,0,0,1,.06,1.11A18.42,18.42,0,0,0,7.93,45.5a1,1,0,0,1-.82.74l-4.9.7a23.36,23.36,0,0,0,0,6.25l4.9.7a1,1,0,0,1,.82.74,18.49,18.49,0,0,0,1.93,4.66,1,1,0,0,1-.06,1.1l-3,4a24.34,24.34,0,0,0,4.42,4.42l4-3a1,1,0,0,1,1.11-.06A18.07,18.07,0,0,0,21,67.67a1,1,0,0,1,.74.82Z" />
      <path d="M59.13,34.71a17.45,17.45,0,0,1-3.35-.33,1,1,0,0,1-.8-.84L54.48,30a13.45,13.45,0,0,1-3.94-2.29L47.2,29a1,1,0,0,1-1.12-.27A17.19,17.19,0,0,1,42.72,23a1,1,0,0,1,.33-1.11l2.82-2.22a13,13,0,0,1,0-4.55l-2.82-2.22a1,1,0,0,1-.33-1.11,17.19,17.19,0,0,1,3.36-5.81,1,1,0,0,1,1.12-.27L50.54,7a13.45,13.45,0,0,1,3.94-2.29L55,1.17a1,1,0,0,1,.8-.84,17.22,17.22,0,0,1,6.71,0,1,1,0,0,1,.8.84l.5,3.55A13.45,13.45,0,0,1,67.73,7l3.34-1.34a1,1,0,0,1,1.12.27,17.19,17.19,0,0,1,3.36,5.81,1,1,0,0,1-.33,1.11L72.4,15.08a13,13,0,0,1,0,4.55l2.82,2.22A1,1,0,0,1,75.55,23a17.19,17.19,0,0,1-3.36,5.81,1,1,0,0,1-1.12.27L67.73,27.7A13.45,13.45,0,0,1,63.79,30l-.5,3.55a1,1,0,0,1-.8.84A17.47,17.47,0,0,1,59.13,34.71Zm-2.27-2.17a15.09,15.09,0,0,0,4.55,0l.49-3.44a1,1,0,0,1,.69-.82,11.17,11.17,0,0,0,4.27-2.48,1,1,0,0,1,1-.19l3.23,1.3A15.19,15.19,0,0,0,73.41,23l-2.67-2.1A1,1,0,0,1,70.3,20a1.49,1.49,0,0,1,0-.21h0a11.08,11.08,0,0,0,0-4.95,1,1,0,0,1,.36-1l2.73-2.14A15,15,0,0,0,71.14,7.8L67.91,9.1a1,1,0,0,1-1-.2,11.51,11.51,0,0,0-4.27-2.48,1,1,0,0,1-.69-.81l-.49-3.44a15.09,15.09,0,0,0-4.55,0l-.49,3.44a1,1,0,0,1-.69.81,11.4,11.4,0,0,0-4.27,2.49,1,1,0,0,1-1,.19L47.13,7.8a15,15,0,0,0-2.27,3.94l2.73,2.14a1,1,0,0,1,.36,1,11.08,11.08,0,0,0,0,4.95h0A1.49,1.49,0,0,1,48,20a1,1,0,0,1-.45.84L44.86,23a15.19,15.19,0,0,0,2.27,3.94l3.23-1.3a1,1,0,0,1,1,.19,11.25,11.25,0,0,0,4.27,2.48,1,1,0,0,1,.69.82Z" />
      <path d="M59.13,24.59a7.24,7.24,0,1,1,7.24-7.24A7.25,7.25,0,0,1,59.13,24.59Zm0-12.47a5.24,5.24,0,1,0,5.24,5.23A5.24,5.24,0,0,0,59.13,12.12Z" />
    </symbol>
    <symbol
      id="icon-mind"
      viewBox="0 0 72.77 84.84"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M61.22,84.84H27.11a1,1,0,0,1-1-1V73.92a2.45,2.45,0,0,0-2.45-2.45H16.54a7.91,7.91,0,0,1-7.9-7.9V53.5L3.05,51.64A4.45,4.45,0,0,1,.64,45.12l8-13.33a32.07,32.07,0,1,1,53.58,24v28a1,1,0,0,1-.3.71A1,1,0,0,1,61.22,84.84Zm-33.11-2H60.22V55.36a1,1,0,0,1,.33-.75A30.05,30.05,0,1,0,10.64,32.07a.92.92,0,0,1-.15.51L2.35,46.15a2.45,2.45,0,0,0,1.33,3.59L10,51.83a1,1,0,0,1,.69,1V63.57a5.9,5.9,0,0,0,5.9,5.9h7.12a4.46,4.46,0,0,1,4.45,4.45Z" />
      <path d="M40.51,38.83A7.79,7.79,0,1,1,48.29,31,7.81,7.81,0,0,1,40.51,38.83Zm0-13.57A5.79,5.79,0,1,0,46.29,31,5.79,5.79,0,0,0,40.51,25.26Z" />
      <path d="M40.51,49.38a19.07,19.07,0,0,1-3-.25,1,1,0,0,1-.83-.85L36.21,45a14.71,14.71,0,0,1-2.49-1l-2.7,2a1,1,0,0,1-1.18,0,18.55,18.55,0,0,1-4.24-4.24,1,1,0,0,1,0-1.18l2-2.7a13.89,13.89,0,0,1-1-2.49l-3.34-.47a1,1,0,0,1-.85-.83,18.07,18.07,0,0,1,0-6,1,1,0,0,1,.85-.83l3.34-.48a14.2,14.2,0,0,1,1-2.49l-2-2.69a1,1,0,0,1,0-1.19,18.5,18.5,0,0,1,4.24-4.23,1,1,0,0,1,1.18,0l2.7,2a14.67,14.67,0,0,1,2.49-1l.47-3.34a1,1,0,0,1,.83-.84,18.25,18.25,0,0,1,6,0,1,1,0,0,1,.83.84l.48,3.34a15,15,0,0,1,2.49,1l2.69-2a1,1,0,0,1,1.19,0,18.46,18.46,0,0,1,4.23,4.23,1,1,0,0,1,0,1.19l-2,2.69a15,15,0,0,1,1,2.49l3.34.48a1,1,0,0,1,.84.83,18.07,18.07,0,0,1,0,6,1,1,0,0,1-.84.83l-3.34.47a14.34,14.34,0,0,1-1,2.49l2,2.7a1,1,0,0,1,0,1.18A18.31,18.31,0,0,1,51.18,46a1,1,0,0,1-1.19,0l-2.69-2a15.06,15.06,0,0,1-2.49,1l-.48,3.33a1,1,0,0,1-.83.85A19,19,0,0,1,40.51,49.38Zm-1.95-2.12a16.41,16.41,0,0,0,3.9,0L42.92,44a1,1,0,0,1,.74-.83,12.37,12.37,0,0,0,3.21-1.33,1,1,0,0,1,1.11.06l2.61,2a16.38,16.38,0,0,0,2.76-2.76l-2-2.62a1,1,0,0,1-.06-1.1,12.29,12.29,0,0,0,1.33-3.22,1,1,0,0,1,.82-.74L56.72,33a15.9,15.9,0,0,0,0-3.9l-3.24-.46a1,1,0,0,1-.82-.74,12.37,12.37,0,0,0-1.33-3.21,1,1,0,0,1,.06-1.11l2-2.61a16.38,16.38,0,0,0-2.76-2.76l-2.61,2a1,1,0,0,1-1.11.06,12.73,12.73,0,0,0-3.21-1.33,1,1,0,0,1-.74-.82l-.46-3.24a15.9,15.9,0,0,0-3.9,0l-.46,3.24a1,1,0,0,1-.74.82,12.8,12.8,0,0,0-3.22,1.33,1,1,0,0,1-1.1-.06l-2.62-2A16.32,16.32,0,0,0,27.67,21l2,2.61a1,1,0,0,1,.06,1.11,12.37,12.37,0,0,0-1.33,3.21,1,1,0,0,1-.83.74l-3.24.46a15.9,15.9,0,0,0,0,3.9l3.24.46a1,1,0,0,1,.83.74,12.29,12.29,0,0,0,1.33,3.22,1,1,0,0,1-.06,1.1l-2,2.62a16.32,16.32,0,0,0,2.75,2.76l2.62-2a1,1,0,0,1,1.1-.06,12.44,12.44,0,0,0,3.22,1.33,1,1,0,0,1,.74.83Z" />
    </symbol>
    <symbol id="icon-case-studies" viewBox="0 0 23.98 28">
      <path d="M23.92,6.68V6.59a.84.84,0,0,0-.2-.29l-6-6a1,1,0,0,0-.3-.2h-.09A1,1,0,0,0,17,0H5A1,1,0,0,0,4,1V8.35a6,6,0,0,0,0,11.3V27a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V6.94A1.1,1.1,0,0,0,23.92,6.68ZM20.57,6H18V3.41ZM2,14a4,4,0,1,1,4,4A4,4,0,0,1,2,14ZM6,26V20a5.94,5.94,0,0,0,3.2-.93l4,4.6a1,1,0,0,0,1.5-1.32l-4.06-4.61A6,6,0,0,0,6,8V2H16V7a1,1,0,0,0,1,1h5V26Z" />
    </symbol>
    <symbol id="icon-whitepapers" viewBox="0 0 21.78 28">
      <path d="M21.78,4.36,17.42,0H0V28H21.78ZM17.11,1.87l2.8,2.8h-2.8ZM1.56,26.44V1.56h14V6.22h4.66V26.44Z" />
      <polygon points="3.11 19.91 3.11 21.47 18.67 21.47 18.67 20.69 18.67 19.91 3.11 19.91" />
      <polygon points="3.11 16.18 3.11 17.73 18.67 17.73 18.67 16.96 18.67 16.18 3.11 16.18" />
      <polygon points="3.11 12.44 3.11 14 18.67 14 18.67 13.22 18.67 12.44 3.11 12.44" />
      <polygon points="3.11 8.71 3.11 10.27 18.67 10.27 18.67 9.49 18.67 8.71 3.11 8.71" />
    </symbol>
  </svg>
);

export default SVGSprite;
